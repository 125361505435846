import { DashboardService } from "src/app/services/dashboard.service";
import { Settings } from "./../models/client";
import { StatusCall } from "./../models/call";
import { ApiService } from "src/app/services/api/api.service";
import { Injectable, NgZone } from "@angular/core";
import { DataService } from "./data.service";
import { CalculationMethods } from "./dashboard.service";
import { firebaseConsts } from "../constants";
import { FirebaseDatabaseService } from "./firebase-database.service";
import { Address } from "../models/address";
import PaymentType, { PaymentTypeList } from "../models/enuns/paymentType";
import CategoryDriver, {
  CategoryDriverList,
} from "../models/enuns/categoryDriver";
import { DriverService } from "../driver.service";
import { AddressService } from "./address.service";
import { Client } from "../models/client";
import { Coordinates } from "../models/interfaces/coordinates";
import { AngularFirestore, Query } from "@angular/fire/firestore";
import { interval } from "rxjs";
import * as moment from "moment";
import { CallService } from "./call.service";
import { parseISO, isAfter } from "date-fns";
import { latLng, marker } from "leaflet";
import { AutoComplete } from "../models/enuns/subsidiarySettings";
import { SubsidiaryService } from "./subsidiary.service";
import { Router } from "@angular/router";
// import { GeolocationService } from "./geolocation.service";
import { StatusDriver } from "../models/enuns/statusDriver";
import differenceInMinutes from "date-fns/fp/differenceInMinutes";
import { Payment } from "../models/interfaces/payment";
import { System } from "../models/enuns/system";
import { INavData } from "@coreui/angular";
import { navItems } from "../_nav";
import { KeysHelper } from "../helpers/keysHelper";
import { GoogleLibrary } from "../models/enuns/googleLibrary";
import { SocketService } from "./socket.service";
import { DriveLocation } from "../models/interfaces/driverLocation";
import { IntegrationApi, RestMethod } from "../models/interfaces/integration-api";
import { FirestoreStatusDoc } from "../models/enuns/firestoreStatusDoc";
import { DynamicType } from "../models/enuns/dynamicType";
import { AnotaAi, Ifood } from "../models/interfaces/partnerIntegration";

@Injectable({
  providedIn: "root",
})
export class DataHandlerService {
  audioMsg: any;
  constructor(
    private data: DataService,
    public dashboardService: DashboardService,
    public db: FirebaseDatabaseService,
    private driverService: DriverService,
    public addressService: AddressService,
    public afs: AngularFirestore,
    public api: ApiService,
    public callService: CallService,
    private subsidiaryService: SubsidiaryService,
    private router: Router,
    private keysHelper: KeysHelper,
    private socketService: SocketService,
    public zone: NgZone
  ) {
    this.audioMsg = new Audio();
    this.audioMsg.src = "../../assets/sound/msg.mp3";
    this.audioMsg.muted = false;
    this.audioMsg.load();
  }

  getRemoteImages() {
    let ref = this.afs.collection(firebaseConsts.collections.images).ref;
    let query: Query;
    query = ref.where("name", "==", "home");
    return this.db.getCollection(query);
  }

  adjustNotification(call: any) {
    if (!call.notifications) return call;
    for (let index = 0; index < call.notifications.length; index++) {
      const dateSeconds = call.notifications[index].createdAt.seconds ? call.notifications[index].createdAt.seconds : 0;
      call.notifications[index].createdAt = new Date(dateSeconds * 1000);
    }
    return call;
  }

  adjustStatusHistoric(call: any) {
    if (!call.statusHistory) return call;
    for (let index = 0; index < call.statusHistory.length; index++) {
      const dateSeconds = call.statusHistory[index].date.seconds ? call.statusHistory[index].date.seconds : 0;
      call.statusHistory[index].date = new Date(dateSeconds * 1000);
    }
    return call;
  }

  checkIfDriverRefusedForwardedCall(call: any) {

    let newCall = call;

    newCall.lastForward = null;
    newCall.lastRefused = null;
    const lastForwards = newCall.statusHistory.filter(row => row.status == 'forwarded');
    const refuses = newCall.statusHistory.filter(row => row.status == 'refused');
    if (lastForwards.length && newCall.status == 'waiting') {
      newCall.lastForward = lastForwards[lastForwards.length - 1];
      const texts = newCall?.lastForward?.justification?.split('Encaminhada para ');
      const driverRefuses = refuses.filter((row) => {
        return texts.includes(row.by.name)
      });
      const myLastRefuse = driverRefuses[driverRefuses.length - 1];
      if (newCall?.lastForward?.date && myLastRefuse?.date) {
        const dataLastForward = new Date(newCall.lastForward.date.seconds * 1000);
        const dataLastRefused = new Date(myLastRefuse.date.seconds * 1000);
        if (isAfter(dataLastRefused, dataLastForward)) newCall.lastRefused = myLastRefuse;
      } else {
        return call;
      }
    };
    return newCall;
  }

  // async watchCurrentCalls() {
  //   const callsChannel = `${this.data.subsidiary.id}:calls`;
  //   console.log(callsChannel);
  //   this.socketService.onFetchCalls(callsChannel)
  //     .subscribe((resp: any) => {
  //       console.log('Update from ', callsChannel);
  //       const calls = resp as Call[];
  //       // call.ky
  //       console.log(calls);
  //     });
  // }

  async removeCallsList(documents: any) {
    const myListCompleteCalls = documents.filter((row: any) => {
      return (row.clientKey == this.data.currentUserId())
    });

    myListCompleteCalls.forEach((doc) => {
      const index = this.data.waintinglist.findIndex(row => {
        return row.key == doc.key
      });
      if (index > -1) this.data.waintinglist.splice(index, 1);
    });

  }

  async setManagerCallsList(documents: any) {

    // const myListCalls = documents.filter((row: any) => {
    //   return (row.clientKey == this.data.currentUserId())
    // });
    // if (myListCalls.length == 0) return;

    documents.forEach((doc) => {
      var obj: any = doc;

      obj.changeType = obj.changeType ? obj.changeType : FirestoreStatusDoc.Added;

      obj.key = doc.key;
      obj.order = obj.key.substring(0, 6);

      if (obj.createdAt)
        obj.date = new Date(obj.createdAt.seconds * 1000);
      else obj.date = new Date(Date.now());

      obj = this.checkIfDriverRefusedForwardedCall(obj);
      obj = this.adjustNotification(obj);
      obj = this.adjustStatusHistoric(obj);

      obj.lastForward = null;
      obj.lastRefused = null;
      const lastForwards = obj.statusHistory.filter(row => row.status == 'forwarded');
      const refuses = obj.statusHistory.filter(row => row.status == 'refused');
      if (lastForwards.length && obj.status == 'waiting') {
        obj.lastForward = lastForwards[lastForwards.length - 1];
        const texts = obj?.lastForward?.justification?.split('Encaminhada para ');
        const driverRefuses = refuses.filter((row) => {
          return texts.includes(row.by.name)
        });
        const myLastRefuse = driverRefuses[driverRefuses.length - 1];
        if (obj?.lastForward?.date && myLastRefuse?.date) {
          const dataLastForward = new Date(obj.lastForward.date.seconds * 1000);
          const dataLastRefused = new Date(myLastRefuse.date.seconds * 1000);
          if (isAfter(dataLastRefused, dataLastForward)) obj.lastRefused = myLastRefuse;
        }
      };

      const alert = obj.statusHistory.find((row) => row.alert == true);
      obj.alert = alert ? alert : null;
      const hasNewMessage = obj.notifications.find(
        (row) =>
          row.read == false && row.senderKey !== this.data.currentUserId()
      );
      if (hasNewMessage) this.audioMsg.play();
      obj.hasNewMessage = hasNewMessage ? true : false;
      obj.changeType = obj.changeType ? obj.changeType : FirestoreStatusDoc.Added;
      const index = this.data.waintinglist.findIndex(row => row.key == obj.key);
      if (obj.changeType == FirestoreStatusDoc.Added && index == -1)
        this.data.waintinglist.push(obj);
      else {
        if (obj.changeType == FirestoreStatusDoc.Modified) {
          this.data.waintinglist[index] = obj;
        } else if (obj.changeType == FirestoreStatusDoc.Removed) {
          this.data.waintinglist.splice(index, 1);
        }
      }

      if (this.data.selectedCall && this.data.selectedCall.key === obj.key
      ) {
        this.data.selectedCall = obj;
      }
      // console.log(this.data.selectedCall);
    });

  }

  async getLastCalls() {
    const uid = this.data.currentUserId();
    const calls = await this.dashboardService.getCalls(uid);
    await this.setManagerCallsList(calls);
  }

  // async loadCallManager() { by socket
  //   try {
  //     this.getLastCalls();
  //     if (this.data.subscribeWatchCallWaitingList) {
  //       await this.data.subscribeWatchCallWaitingList.unsubscribe();
  //     }
  //     const callsChannel = `${this.data.subsidiary.id}:calls`;
  //     this.data.subscribeWatchCallWaitingList = this.socketService.onFetchCalls(callsChannel)
  //       .subscribe((documentSnapshots: any) => {
  //         this.setManagerCallsList(documentSnapshots);
  //       });
  //   } catch (err) {
  //     console.warn('Error on onFetchCallsComplete: ', err);
  //   }
  // }

  async loadCallManager() {
    try {
      if (this.data.subscribeWatchCallWaitingList) {
        await this.data.subscribeWatchCallWaitingList.unsubscribe();
      }

      this.data.subscribeWatchCallWaitingList = await this.callService
        .watchPendindCalls(this.data.currentUserId(), this.data.subsidiary.id)
        .subscribe((documentSnapshots) => {
          const list = [];
          documentSnapshots.forEach((item) => {
            list.push({
              ...item.payload.doc.data(),
              key: item.payload.doc.id,
              changeType: item.type
            });
          });

          this.setManagerCallsList(list);
          // this.data.waintinglist = [];
          // documentSnapshots.forEach((doc) => {
          //   var obj: any = doc.payload.doc.data();
          //   obj.key = doc.payload.doc.id;
          //   obj.order = obj.key.substring(0, 6);
          //   if (obj.createdAt)
          //     obj.date = new Date(obj.createdAt.seconds * 1000);
          //   else obj.date = new Date(Date.now());

          //   obj = this.checkIfDriverRefusedForwardedCall(obj);

          //   obj.lastForward = null;
          //   obj.lastRefused = null;
          //   const lastForwards = obj.statusHistory.filter(row => row.status == 'forwarded');
          //   const refuses = obj.statusHistory.filter(row => row.status == 'refused');
          //   if (lastForwards.length && obj.status == 'waiting') {
          //     obj.lastForward = lastForwards[lastForwards.length - 1];
          //     const texts = obj?.lastForward?.justification?.split('Encaminhada para ');
          //     const driverRefuses = refuses.filter((row) => {
          //       return texts.includes(row.by.name)
          //     });
          //     const myLastRefuse = driverRefuses[driverRefuses.length - 1];
          //     if (obj?.lastForward?.date && myLastRefuse?.date) {
          //       const dataLastForward = new Date(obj.lastForward.date.seconds * 1000);
          //       const dataLastRefused = new Date(myLastRefuse.date.seconds * 1000);
          //       if (isAfter(dataLastRefused, dataLastForward)) obj.lastRefused = myLastRefuse;
          //     }
          //   };

          //   if (this.data.ifoodActivated) {
          //     const callDate = obj.createdAt
          //       ? new Date(obj.createdAt.seconds * 1000)
          //       : new Date(Date.now());
          //     const now = moment();
          //     const diffMinutes = now.diff(callDate, "minute");
          //     // obj.minsToCall = update yime to autocall
          //   } else {
          //     //
          //   }

          //   const alert = obj.statusHistory.find((row) => row.alert == true);
          //   obj.alert = alert ? alert : null;
          //   const hasNewMessage = obj.notifications.find(
          //     (row) =>
          //       row.read == false && row.senderKey !== this.data.currentUserId()
          //   );
          //   if (hasNewMessage) this.audioMsg.play();
          //   obj.hasNewMessage = hasNewMessage ? true : false;
          //   this.data.waintinglist.push(obj);
          //   if (this.data.selectedCall && this.data.selectedCall.key === obj.key
          //   ) {
          //     this.data.selectedCall = obj;
          //   }

          // });
        });
    } catch (err) {
      console.warn('Error on onFetchCallsComplete: ', err);
    }
  }



  async watchDriversAvailables(subsidiary) { }

  watchBalance(ownerId: string) {
    if (this.data.subscribeWatchBalance) {
      this.data.subscribeWatchBalance.unsubscribe();
    }
    this.data.subscribeWatchBalance = this.db
      .watchBalance(ownerId)
      .subscribe((documentSnapshots) => {
        this.data.balance = null;
        documentSnapshots.forEach((doc) => {
          var obj: any = doc.payload.doc.data();
          this.data.balance = obj;
        });
      });
  }

  getIcon(driver) {
    if (driver.currentCall.id) {
      return driver.currentCall.status === "ongoing"
        ? this.data.iconbusy
        : this.data.icon;
    } else {
      return this.data.iconfree;
    }
  }

  updateMap() {
    const data = this.data;
    this.data.layers.forEach((layer) => {
      data.map.removeLayer(layer);
    });

    this.data.layers.forEach((newLayer) => {
      this.data.map.addLayer(newLayer);
    });
  }

  async clearAllDrivers(statusDriver: string) {
    const data = this.data;
    if (!this.data.map) return;
    this.data.map.eachLayer(function (layer: any) {
      if (layer.driver && layer.status == statusDriver) {
        data.map.removeLayer(layer);
      }
    });
  }

  async clearDriversOnMap(statusDriver: string) {
    for (let index = 0; index < this.data.layers.length; index++) {
      const element = this.data.layers[index];
      if (element.driver && element.status == statusDriver) {
        this.data.layers.splice(index, 1);
      }
    }
    this.updateMap();
  }

  clearMap(layers) {
    const data = this.data;
    layers.forEach((layer) => {
      data.map.removeLayer(layer);
    });
  }

  async setDriverOnMap(driver) {
    const driverMarker: any = marker(
      [driver.position.latitude, driver.position.longitude],
      {
        icon: this.getIcon(driver),
      }
    );
    driverMarker.status = driver.driver.status;
    driverMarker.driver = driver.driver.id;
    driverMarker.bindPopup(this.driverService.getProfile(driver));
    this.data.layers.push(driverMarker);
    this.updateMap();
  }

  async watchCurrentDriversBusy() {
    const channel = `${this.data.subsidiary.id}:${this.data.client.id}`;
    // console.log('Channel', channel);
    this.socketService.onFetchBusyDrivers(channel)
      .subscribe((resp: any) => {
        const driver = resp.data as DriveLocation;
        this.insertUpdateListDrivers(driver);
        this.updateDriversMap();
      });
  }

  async watchCurrentDriversFree() {
    const channel = `${this.data.subsidiary.id}:freedrivers`;

    this.socketService.onFetchFreeDrivers(channel)
      .subscribe((resp: any) => {
        const driver = resp.data as DriveLocation;
        this.deleteFromListDrivers(driver);
        this.updateDriversMap();
      });
  }

  async updateDriversMap() {
    await this.clearAllDrivers(StatusDriver.Busy);
    await this.clearDriversOnMap(StatusDriver.Busy);
    this.data.activelist = [];

    for (let index = 0; index < this.data.activelistScoket.length; index++) {
      const active = this.data.activelistScoket[index];
      // console.log(active);
      this.data.activelist.push(active);
      this.setDriverOnMap(active);
    }

  }

  deleteFromListDrivers(position: DriveLocation) {
    const index = this.data.activelistScoket.findIndex(row => {
      return row.key == position.driver.id;
    });

    if (index >= 0) this.data.activelistScoket.splice(index, 1);

  }

  insertUpdateListDrivers(position: DriveLocation) {
    const index = this.data.activelistScoket.findIndex(row => {
      return row.key == position.driver.id;
    });

    position.key = position.driver.id;
    position.distance = 0;
    position.driver.categoryName = CategoryDriverList.find((r) => r.key === position.driver.category).value;
    position.driver.status = StatusDriver.Busy;

    if (index == -1) {
      this.data.activelistScoket.push(position);
    } else {
      this.data.activelistScoket[index] = position;
    }

  }

  // async watchCurrentDeliveries() {
  //   try {
  //     if (this.data.subscribeWatchCurrentDeliveries) {
  //       await this.data.subscribeWatchCurrentDeliveries.unsubscribe();
  //     }

  //     this.data.subscribeWatchCurrentDeliveries = await this.dashboardService
  //       .watchCurrentDeliveries(this.data.currentUserId())
  //       .subscribe(
  //         async (documentSnapshots) => {
  //           this.clearAllDrivers(StatusDriver.Busy);
  //           this.data.activelist = [];
  //           await this.clearDriversOnMap(StatusDriver.Busy);
  //           documentSnapshots.forEach((doc) => {
  //             var obj: any = doc.payload.doc.data();
  //             obj.key = doc.payload.doc.id;
  //             obj.distance = 0;
  //             obj.driver.categoryName = CategoryDriverList.find(
  //               (r) => r.key === obj.driver.category
  //             ).value;
  //             obj.driver.status = StatusDriver.Busy;
  //             this.data.activelist.push(obj);
  //             this.setDriverOnMap(obj);
  //           });
  //         },
  //         (err) => console.warn(err)
  //       );
  //   } catch (err) {
  //     console.warn(err, "Error on watchCurrentDeliveries");
  //   }
  // }

  async subscribeWatchTariffsDynamics(subsidiaryId: string, driverCategory: number) {
    if (this.data.subscribeWatchTariffsDynamics) {
      await this.data.subscribeWatchTariffsDynamics.unsubscribe();
    }
    const clientId = this.data.client.id;
    this.data.subscribeWatchTariffsDynamics = this.db
      .watchTariffsDynamics(subsidiaryId, driverCategory)
      .subscribe((documentSnapshots) => {
        this.data.dynamicTariff = null;
        if (documentSnapshots?.length) {
          let doc = documentSnapshots[0]?.payload.doc.data();
          doc.id = documentSnapshots[0]?.payload.doc.id;
          if (doc.onlyCustomers?.length) {
            if (doc.onlyCustomers?.includes(clientId)) this.data.dynamicTariff = doc;
          } else if (!doc.exceptionCustomers.includes(clientId)) {
            this.data.dynamicTariff = doc;
          }
        }
      });

    if (this.data.subscribeWatchTariffsDynamicsScheduled) {
      await this.data.subscribeWatchTariffsDynamicsScheduled.unsubscribe();
    }

    this.data.subscribeWatchTariffsDynamicsScheduled = this.db
      .watchTariffsDynamicsScheduled(subsidiaryId, driverCategory)
      .subscribe((documentSnapshots) => {
        this.data.dynamicTariffScheduled = null;
        if (documentSnapshots?.length) {
          let doc = documentSnapshots[0]?.payload.doc.data();
          doc.id = documentSnapshots[0]?.payload.doc.id;
          if (doc.onlyCustomers?.length) {
            if (doc.onlyCustomers?.includes(clientId)) this.data.dynamicTariffScheduled = doc;
          } else if (!doc.exceptionCustomers.includes(clientId)) {
            this.data.dynamicTariffScheduled = doc;
          }
        }
      });
  }

  async subscribeWatchCompanyTariffs(calculationMethod, driverCategory) {
    if (this.data.subscribeWatchTariffs) {
      await this.data.subscribeWatchTariffs.unsubscribe();
    }
    this.data.subscribeWatchTariffs = this.db
      .watchCompanyTariffs(
        this.data.currentUserId(),
        calculationMethod,
        driverCategory
      )
      .subscribe(
        (documentSnapshots) => {
          this.data.currentTariffs = [];
          documentSnapshots.forEach((doc) => {
            var obj: any = doc.payload.doc.data();
            obj.key = doc.payload.doc.id;
            obj.dynamicValue = 0;
            obj.selected = false;
            this.data.currentTariffs.push(obj);
          });
          // console.log(this.data.currentTariffs);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  async subscribeWatchSubsidiaryTariffs(calculationMethod, driverCategory) {
    if (this.data.subscribeWatchTariffs) {
      await this.data.subscribeWatchTariffs.unsubscribe();
    }
    this.data.subscribeWatchTariffs = this.db
      .watchSubsidiaryTariffs(
        this.data.client.subsidiary,
        calculationMethod,
        driverCategory
      )
      .subscribe((documentSnapshots) => {
        this.data.currentTariffs = [];
        documentSnapshots.forEach((doc) => {
          var obj: any = doc.payload.doc.data();
          obj.key = doc.payload.doc.id;
          obj.dynamicValue = 0;
          obj.selected = false;
          this.data.currentTariffs.push(obj);
        });
        // console.log(this.data.currentTariffs);
      });
  }

  getDynamicsTariffs(subsidiaryId: string, driverCategory: number) {
    try {
      this.subscribeWatchTariffsDynamics(subsidiaryId, driverCategory);
    } catch (err) {
      console.error(err);
    }
  }

  getTariffs(calculationMethod, driverCategory: number) {
    try {
      this.dashboardService
        .getTariffsByCompany(calculationMethod, driverCategory)
        .then((res: any) => {
          // console.log(res);
          if (res && res.length > 0) {
            console.log("Using Tariffs Company");
            this.subscribeWatchCompanyTariffs(
              calculationMethod,
              driverCategory
            );
          } else {
            if (calculationMethod && driverCategory) {
              this.dashboardService
                .getTariffsBySubsidiary(calculationMethod, driverCategory)
                .then((res: any) => {
                  if (res && res.length > 0)
                    this.subscribeWatchSubsidiaryTariffs(
                      calculationMethod,
                      driverCategory
                    );
                  else this.data.currentTariffs = [];
                });
            }
          }
        });
    } catch (err) {
      console.warn(err);
    }
  }

  private dateString2Date(dateString) {
    var dt = dateString.split(/\-|\s/);
    return new Date(dt.slice(0, 3).reverse().join("-") + " " + dt[3]);
  }

  converteDataFormatoBr(data: string) {
    let prazoDate = new Date(data);
    let year = prazoDate.getFullYear();
    let month = String(prazoDate.getMonth() + 1);
    if (month.length == 1) month = "0" + month;
    let day = prazoDate.getDate();
    const dateStrBr = `${day}/${month}/${year}`;
    return dateStrBr;
  }

  getNow() {
    let year = new Date().getFullYear().toString();
    let month = new Date().getMonth().toString();
    let day = new Date().getDate().toString();
    return `${day}/${month}/${year}`;
  }

  loadTransactTypes() {
    this.data.transactTypes = [];
  }

  async loadCategoriesDriver() {
    this.data.categoryTypes = [];
    CategoryDriverList.forEach(async (element) => {
      let index = -1;

      if (this.data.client.categories) {
        index = await this.data.client.categories.findIndex((row) => {
          return row == element.key;
        });

        if (index > -1) {
          this.data.categoryTypes.push({
            key: element.key,
            value: element.value,
          });
        }
      }
    });

    this.data.defaultCategoryType = CategoryDriver.Motoboy;
    const catDriver = CategoryDriverList.find((row) => {
      return row.key == CategoryDriver.Motoboy;
    });
    this.data.defaultCategoryTypeName = catDriver.value;
  }

  async loadPaymentTypes() {
    this.data.paymentTypes = [];
    this.data.paymentTypesIfoodList = [];

    await PaymentTypeList.forEach(async (element) => {
      let index = -1;

      if (this.data.client.paymentTypes) {
        index = await this.data.client.paymentTypes.findIndex((row) => {
          return row == element.key;
        });

        if (index > -1) {
          const paymentItem = {
            key: element.key,
            value: element.value,
            checked: index > -1,
          };
          this.data.paymentTypes.push(paymentItem);
          paymentItem.key = Number(element.key);
          this.data.paymentTypesIfoodList.push(paymentItem);
        }
      }
    });

    await this.setDefaultPaymente();
  }

  async updatePartnerSettings() {
    const clientJsonFormat = Client.prepareData(this.data.client);
    const ref = this.db.afs
      .collection(firebaseConsts.collections.clients)
      .doc(this.data.currentUserId()).ref;
    return ref.update({ ifood: clientJsonFormat.ifood, anotaai: clientJsonFormat.anotaai });
  }

  setDefaultPaymente() {
    if (
      this.data.paymentTypes.findIndex(
        (row) => row.key == PaymentType.Prepago
      ) > -1
    ) {
      this.data.defaultPaymentType = PaymentType.Prepago;
    } else if (
      this.data.paymentTypes.findIndex(
        (row) => row.key == PaymentType.Faturado
      ) > -1
    ) {
      this.data.defaultPaymentType = PaymentType.Faturado;
    } else {
      if (this.data.paymentTypes.length == 1)
        this.data.defaultPaymentType = this.data.paymentTypes[0].key;
      else this.data.defaultPaymentType = PaymentType.Money;
    }
    const paymentType = this.getPayment(this.data.defaultPaymentType);
    this.setPaymentLocalStorage(paymentType);
  }

  getAndUpdatePlaceId(client: Client) {
    if (client.address && !client.address.placeId) {
      //caso nao tenha o placeid, obtem e atualiza no cadastro do client
      //A API vai usar esse place id para calcular e salvar rotas
      const origin = client.address.location as Coordinates;
      this.addressService
        .getAddressByReverseGeocoding(origin.latitude, origin.longitude)
        .then((res: any) => {
          if (res && res.status === "OK") {
            const result = res.results[0];
            if (result) {
              const docRef = this.afs
                .collection(firebaseConsts.collections.clients)
                .doc(client.id);
              const update = {
                address: {
                  ...client.address,
                  placeId: result.place_id,
                },
              };
              docRef.update(update).then((res) => {
                // console.log(res);
              });
            }
          }
        });
    }
  }

  async getSubsidiarySettings(subsidiary) {
    let ref = this.afs.collection(firebaseConsts.collections.subsidiaries).doc(subsidiary).ref;
    const data = await ref.get();
    const doc = {
      id: data.id,
      key: data.id,
      ...data.data()
    }
    return doc;
  }

  checkIfoodStatusStore() {
    const endPpint = "auth/ifood-status";
    this.api.get(endPpint).then((res: any) => {
      this.data.dashboard.runZone(res);
    });
  }

  callDrivers() {
    // if (this.data.client.ifood && this.data.client.ifood.partnerTimeAutoCall && this.data.client.ifood.partnerTimeAutoCall > 0) {
    //   const pends = this.data.waintinglist.filter(row => {
    //     return row.status === StatusCall.imported
    //   })
    //   pends.forEach(async element => {
    //     const callDate = new Date(element.createdAt.seconds * 1000);
    //     const now = moment();
    //     const diffMinutes = now.diff(callDate, "minute");
    //     // console.log('diffMinutes: ' + diffMinutes);
    //     // console.log(element.key);
    //     if (diffMinutes >= this.data.client.ifood.partnerTimeAutoCall) {
    //       this.data.selectedCall = element;
    //       await this.dashboardService.sendCallIfood(element);
    //     }
    //   });
    // }
  }

  async checkLock(diffMins) {
    this.data.lastCheckLock = new Date(Date.now());
    console.log('checkLock', this.data.client.subsidiary);
    const subsidiaryData = await this.db.getDataById(
      firebaseConsts.collections.subsidiaries,
      this.data.client.subsidiary
    );
    if (!subsidiaryData.disabledAt) {
      this.data.subsidiary.disabledAt = null;
      this.data.warning = "";
      this.data.lockHoursRemains = null;
      this.data.subscriptionCheckLock.unsubscribe();
    } else {
      this.data.lockHoursRemains = 2280 - diffMins;
      console.log(this.data.lockHoursRemains);
      if (this.data.lockHoursRemains > 0) {
        const minuts = this.data.lockHoursRemains > 1 ? "minutos" : "minuto";
        this.data.warning = `Sistema será suspenso em ${this.data.lockHoursRemains} ${minuts}`;
      } else {
        this.data.warning = `Sistema suspenso para realizar chamadas`;
      }
      console.log("lockHoursRemains: ", this.data.lockHoursRemains);
    }
  }

  updateTariffs() {
    const dynamicTariff = this.data.dynamicTariffScheduled ? this.data.dynamicTariffScheduled : this.data.dynamicTariff;
    for (let index = 0; index < this.data.currentTariffs[0].fixedValues.length; index++) {
      if (dynamicTariff) {
        if (dynamicTariff.type == DynamicType.FixedValue) {
          this.data.currentTariffs[0].fixedValues[index].dynamicValue = this.data.currentTariffs[0].fixedValues[index].value + dynamicTariff.value;
        } else {
          this.data.currentTariffs[0].fixedValues[index].dynamicValue = this.data.currentTariffs[0].fixedValues[index].value * dynamicTariff.value;
        }
      } else {
        this.data.currentTariffs[0].fixedValues[index].dynamicValue = 0;
      }
    }
  }

  async fetchInfo() {
    if (this.data.subscriptionCheckIfood)
      this.data.subscriptionCheckIfood.unsubscribe();

    if (this.data.subscriptionCheckDynamicTariff) this.data.subscriptionCheckDynamicTariff.unsubscribe();

    const checkEachsec20 = interval(1000 * 20);
    this.data.subscriptionCheckDynamicTariff = checkEachsec20.subscribe(async (val) => {
      this.updateTariffs();
      if (this.data.dynamicTariffScheduled) {
        const now = new Date();
        const activateUntil = new Date(this.data.dynamicTariffScheduled.activateUntil?.seconds * 1000);
        if (isAfter(now, activateUntil)) {
          this.data.dynamicTariffScheduled = null;
        }
      }
    });

    if (this.data.subsidiary?.disabledAt) {

      const source = interval(1000 * 5);
      this.data.subscriptionCheckLock = source.subscribe(async (val) => {
        const now = new Date(Date.now());
        const lockDate = new Date(
          this.data.subsidiary.disabledAt.seconds * 1000
        );
        const diffMins = differenceInMinutes(lockDate, now);
        if (!this.data.lastCheckLock) {
          this.data.lastCheckLock = now;
          await this.checkLock(diffMins);
        }
        const diff = differenceInMinutes(this.data.lastCheckLock, now);
        if (diff >= 30) {
          await this.checkLock(diffMins);
        }
      });
    } else {
      if (this.data.subscriptionCheckLock)
        this.data.subscriptionCheckLock.unsubscribe();
    }

    const source = interval(1000 * 30);
    this.data.subscriptionCheckIfood = source.subscribe((val) => {
      if (
        this.data.client.ifood &&
        this.data.client.ifood.merchantId &&
        this.data.client.ifood.merchantId.length > 0 &&
        !this.data.client.ifood.disabledAt
      ) {
        this.checkIfoodStatusStore();
      }
      // this.callDrivers();
      // const now = moment();
      // const diffMinutes = now.diff(this.data.lastUpdateDriverList, "minute");
      // if (diffMinutes == 0) {
      //   this.driverService.loadActiveDrivers();
      // }
    });

  }

  // mountTotalsWeek() {
  //   this.getCallsWeek().then((res: any) => {
  //     this.data.weekTotal = [];

  //     for (let index = 0; index < this.data.paymentTypes.length; index++) {
  //       const element = this.data.paymentTypes[index];

  //       const list: any = res.filter((row) => {
  //         return row.payment.type === element.key;
  //       });

  //       let total = 0;
  //       if (list.length > 0) {
  //         total = list
  //           .map((row) => row.payment.value)
  //           .reduce((prev, next) => prev + next);

  //         if (total > 0) {
  //           this.data.weekTotal.push({ name: element.value, total: total });
  //         }
  //       }

  //       this.data.paymentTypes[index].total = total;
  //     }
  //     this.data.columns = 12 / this.data.weekTotal.length;
  //   });
  // }

  // getCallsWeek() {
  //   const startUpdateDate = parseISO(
  //     moment().subtract(7, "days").toISOString()
  //   );
  //   let ref = this.db.afs.collection(firebaseConsts.collections.calls).ref;
  //   let query: Query;
  //   query = ref
  //     .where("origin.clientId", "==", this.data.currentUserId())
  //     .where("status", "==", StatusCall.complete)
  //     .orderBy("updatedAt")
  //     .startAt(startUpdateDate);
  //   return this.db.getCollection(query);
  // }

  initMap() {
    this.data.initMap(
      latLng(this.data.latitudeClient, this.data.longitudeClient)
    );

    const thisInst = this;
    this.data.map.on('click', function (event) {
      console.log(event);
    });

    // this.data.map.on('dblclick', function (event) {
    //   // thisInst.data.map.clicked = 0;
    //   // thisInst.data.map.zoomIn();
    //   console.log('dblclick');
    // });

  }

  checkAutoCompleteToUse() {
    if (
      !this.data.subsidiary.settings ||
      !this.data.subsidiary.settings.useAutoComplete
    ) {
      this.data.subsidiary.settings.useAutoComplete = AutoComplete.Boaztech;
    }
  }

  clearAllMap() {
    this.clearMap(this.data.layers);
    for (let index = 0; index < this.data.layers.length; index++) {
      const element = this.data.layers[index];
      // if (!element.driver) {
      this.data.layers.splice(index);
      // }
    }
    this.clearAllDrivers(StatusDriver.Free);
    this.clearAllDrivers(StatusDriver.Busy);

    this.zone.run(() => {
      this.router.navigate(["dashboard"]);
    });

  }

  async getKeys() {
    const keys = await this.keysHelper.getGoogleKeys(
      GoogleLibrary.Placedetails
    );
    this.data.keys = keys;
  }

  initIntegration(userdata: any) {
    if (!userdata) return;

    if (userdata && !userdata.integration) {
      userdata.integration = {
        disabledAt: null,
        activated: false,
        system: null,
        secret: null,
        webhookLocationParams: {
          url: '',
          method: RestMethod.POST,
          headers: '',
          params: ''
        },
        webhookStatusParams: {
          url: '',
          method: RestMethod.POST,
          headers: '',
          params: ''
        },
        allowCreateCallByAnother: false
      } as IntegrationApi;
    }
    if (userdata?.integration?.webhookLocationParams == null) {
      userdata.integration.webhookLocationParams = {
        url: '',
        method: RestMethod.POST,
        headers: '',
        params: ''
      }
    }

    if (userdata?.integration?.webhookStatusParams == null) {
      userdata.integration.webhookStatusParams = {
        url: '',
        method: RestMethod.POST,
        headers: '',
        params: ''
      }
    }

    return userdata.integration;

  }

  async prepareIfoodParam() {
    if (
      this.data.client.ifood &&
      this.data.client.ifood.merchantId &&
      this.data.client.ifood.merchantId.length > 0 &&
      !this.data.client.ifood.disabledAt
    ) {
      this.data.ifoodActivated = true;
      this.checkIfoodStatusStore();
      const hasPayment = this.data.client.paymentTypes.filter(
        (row) => row == this.data.client.ifood.paymentType
      );
      if (hasPayment.length == 0) {
        if (this.data.client.paymentTypes.includes(PaymentType.Prepago)) {
          this.data.client.ifood.paymentType = PaymentType.Prepago;
        } else if (
          this.data.client.paymentTypes.includes(PaymentType.Money)
        ) {
          this.data.client.ifood.paymentType = PaymentType.Money;
        } else {
          this.data.client.ifood.paymentType = PaymentType.Prepago;
        }
      }
    } else {
      this.data.ifoodActivated = false;
    }
  };

  async prepareAnotaAiParam() {
    if (
      this.data.client.anotaai &&
      this.data.client.anotaai.merchantId &&
      this.data.client.anotaai.merchantId.length > 0 &&
      !this.data.client.anotaai.disabledAt
    ) {
      this.data.anotaaiActivated = true;
      const hasPayment = this.data.client.paymentTypes.filter(
        (row) => row == this.data.client.anotaai.paymentType
      );
      if (hasPayment.length == 0) {
        if (this.data.client.paymentTypes.includes(PaymentType.Prepago)) {
          this.data.client.anotaai.paymentType = PaymentType.Prepago;
        } else if (
          this.data.client.paymentTypes.includes(PaymentType.Money)
        ) {
          this.data.client.anotaai.paymentType = PaymentType.Money;
        } else {
          this.data.client.anotaai.paymentType = PaymentType.Prepago;
        }
      }
    } else {
      this.data.anotaaiActivated = false;
    }
  };

  async updateClientData(uid) {
    this.data.client = new Client();

    this.clearAllMap();
    const iduser = uid ? uid : this.data.client.id;
    await this.db
      .getDataById(firebaseConsts.collections.clients, iduser)
      .then(async (userdata) => {
        if (!userdata || !userdata?.ifood) userdata.ifood = new Ifood();
        if (!userdata || !userdata?.anotaai) userdata.anotaai = new AnotaAi();
        if (!userdata || !userdata?.settings) {
          userdata.settings = new Settings();
        }
        userdata.integration = this.initIntegration(userdata);
        this.data.client = userdata;
        this.data.setInfoConfigOptions();
        this.getAndUpdatePlaceId(this.data.client);
        await this.db
          .getDataById(
            firebaseConsts.collections.subsidiaries,
            this.data.client.subsidiary
          )
          .then(async (subsidiary) => {
            this.data.latitudeSubsidiary = subsidiary.address.location.latitude;
            this.data.longitudeSubsidiary =
              subsidiary.address.location.longitude;
            this.data.subsidiary = {
              id: this.data.client.subsidiary,
              description: subsidiary.description,
              settings: subsidiary.settings ? subsidiary.settings : null,
              disabledAt: subsidiary.disabledAt ? subsidiary.disabledAt : null,
            };
            this.checkAutoCompleteToUse();
            this.getKeys();
            if (userdata) {
              if (userdata.address) {
                this.data.registerPending = false;
                this.data.latitudeClient = userdata.address.location.latitude;
                this.data.longitudeClient = userdata.address.location.longitude;

                if (userdata.address.locationUpdatedAt) {
                  const lastUpdate = new Date(userdata.address.locationUpdatedAt.seconds * 1000);
                  const now = moment();
                  const diffDay = now.diff(lastUpdate, "day");
                  this.data.allowSetLocationDraggable = diffDay > 20;
                } else {
                  this.data.allowSetLocationDraggable = true;
                }

                this.data.currentLocation = latLng(
                  userdata.address.location.latitude,
                  userdata.address.location.longitude
                );
                // this.data.setCurrentLocationLayer(latLng(userdata.address.location.latitude, userdata.address.location.longitude));
                // this.initMap();

                this.data.centerBound.lat = this.data.latitudeClient;
                this.data.centerBound.lng = this.data.longitudeClient;
                this.data.googlePlacesOptions.bounds =
                  await this.data.getBounds(
                    this.data.centerBound,
                    this.data.distanceKmStrictBounds
                  );

                this.data.updateLocation();

                await this.getLastCalls();
                this.loadCallManager();
                // this.watchCurrentCalls();
                this.watchCurrentDriversBusy();
                this.watchCurrentDriversFree();
                // this.watchCurrentDeliveries();
                // TODO refazer usando a collection driver e nao a online
                // this.driverService.getAvailables(this.data.client.subsidiary);

                await this.subscribeWatchTariffsDynamics(this.data.client.subsidiary, CategoryDriver.Motoboy)

                await this.loadCategoriesDriver();
                await this.getTariffs(
                  CalculationMethods.kilometerTable,
                  CategoryDriver.Motoboy
                );
                // await this.getSubsidiarySettings(this.data.client.subsidiary).then((res: any) => {              
                //   if (res) this.data.subsidiary = res;
                // });
                await this.loadPaymentTypes();
                // console.log(this.data.defaultPaymentType);
                if (this.data.defaultPaymentType == PaymentType.Prepago) {
                  this.watchBalance(uid);
                }

                //this.mountTotalsWeek(); TODO Verificar uso!!
                this.data.warning = "";
                this.fetchInfo();
                // this.getAvailablesDriverNear(this.data.subsidiary.id);
                this.prepareIfoodParam();
                this.prepareAnotaAiParam();
                //await this.updatePartnerSettings(); TODO verificar necessidade de atualizar no backend

                this.getRemoteImages().then((res: any) => {
                  this.data.images = res.filter((row) => {
                    return row.name !== "";
                  });
                  if (this.data.images.length > 0) {
                    this.data.homeImage = this.data.images[0].list[0];
                  }
                });
                await this.initMap();
              } else {
                this.data.client.address = new Address();
                this.data.latitudeClient = null;
                this.data.longitudeClient = null;
                this.data.registerPending = true;
                this.data.warning = this.data.textCompleteRegister;
                // this.ngZone.run(() => {
                //   this.router.navigate(["registrations/company"]);
                // });
              }
              this.data.news = [];
              this.getNews().then((res: any) => {
                this.data.news = res;
                const items = navItems;
                // const items = this.data.getNavItems();
                // console.log(items);

                const newItem: INavData = {
                  name: "Novidades",
                  url: "/news",
                  icon: "icon-news",
                  badge: {
                    variant: "danger",
                    text: this.data.news.length.toString(),
                  },
                };
                items.push(newItem);
                this.data.setNavItems(items);
              });
            }
          })
          .catch((err) => {
            console.warn(err);
          });
      });
  }

  getNews() {
    let ref = this.db.afs.collection(firebaseConsts.collections.news).ref;
    let query: Query;
    query = ref
      .where("system", "==", System.ClientWeb)
      .where("status", "==", "A")
      .orderBy("createdAt", "desc");
    return this.db.getCollection(query);
  }

  getMySubsidiaries(ownerId: string | null) {
    let ref = this.db.afs.collection(firebaseConsts.collections.subsidiaries).ref;
    let query: Query;
    query = ref.where("disabledAt", "==", null).orderBy("description");
    if (ownerId) {
      query = ref.where("owner.id", "==", ownerId)
    }
    return this.db.getCollection(query);
  }

  loadSubsidiaries(list: any) {
    this.data.subsidiaries = [];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      this.data.subsidiaries.push({
        id: element.key,
        text: `${element.description}`,
        name: element.description,
      });
    }
    this.data.subsidiarySelected = this.data.subsidiaries.length ? this.data.subsidiaries[0] : null;
  }

  async loadClients(subsidiary: string | null) {
    this.data.clientsBySubsidiary = await this.subsidiaryService.getClients(subsidiary);
    const client = this.data.clientsBySubsidiary[0];
    this.data.client = client;

    this.data.clientSelect2 = [];
    for (let index = 0; index < this.data.clientsBySubsidiary.length; index++) {
      const element = this.data.clientsBySubsidiary[index];
      const city = element.address
        ? ` - ${element.address.city}-${element.address.state}`
        : "";
      this.data.clientSelect2.push({
        id: element.key,
        text: `${element.name}`,
        name: element.name,
      });
    }
    return client;
  }

  async prepareToAdminManager(userType) {
    if (userType.claims?.admin) {
      const subs = await this.getMySubsidiaries(null);
      await this.loadSubsidiaries(subs);
      const idSub = this.data.subsidiarySelected.id ? this.data.subsidiarySelected.id : null;
      await this.setCurrentClient(idSub);
    } else if (userType.claims?.manager) {
      const subs: any = await this.getMySubsidiaries(userType.id);
      await this.loadSubsidiaries(subs);
      await this.setCurrentClient(this.data.subsidiarySelected.id);
    }
  }

  async setCurrentClient(subdId: string | null) {
    const client = await this.loadClients(subdId);
    const id = client.key ? client.key : client.id;
    this.data.setCurrentUserId(id);
    await this.updateClientData(id);
    if (!this.data.subsidiaries || this.data.subsidiaries?.length == 0) {
      this.router.navigate(["login"]);
    } else {
      this.router.navigate(["dashboard"]);
    }
  }

  async getAvailablesDriverNear(subsidiary) {
    const limit = this.data.activelist.length
      ? this.data.activelist.length + 2
      : 1;
    console.log("limit: " + limit);

    if (this.data.subscribeDriversAvailable)
      this.data.subscribeDriversAvailable.unsubscribe();
    this.data.subscribeDriversAvailable = this.driverService
      .watchDriversAvailables(subsidiary, 1, limit)
      .subscribe(async (documentSnapshots) => {
        this.data.nearOnlineDrivers = [];
        await this.clearAllDrivers(StatusDriver.Free);
        await this.clearDriversOnMap(StatusDriver.Free);
        console.log("Updated drivers: " + documentSnapshots.length);
        await documentSnapshots.forEach(async (doc) => {
          var obj: any = doc;
          const names = obj.driver.name.split(" ");
          const driver = {
            driver: {
              id: obj.id,
              name: names.length > 0 ? names[0] : obj.driver.name,
              phone: obj.driver.phone,
              category: obj.driver.category,
              categoryName: CategoryDriverList.find(
                (r) => r.key === obj.driver.category
              ).value,
              photo: obj.driver.photo,
              status: StatusDriver.Free,
            },
            currentCall: obj.currentCall,
            location: {
              latitude: obj.location.latitude,
              longitude: obj.location.longitude,
            },
            stationary: obj.stationary,
            distance: Number(obj.hitMetadata.distance).toFixed(1),
            updatedAt: obj.updatedAt
              ? new Date(obj.updatedAt.seconds * 1000)
              : new Date(obj.createdAt.seconds * 1000),
          };
          const now = new Date(Date.now());
          const diff = differenceInMinutes(driver.updatedAt, now);
          if (diff < 6) this.setDriverOnMap(driver);
        });
      });
  }

  getPayment(key) {
    const types = this.data.paymentTypes.filter((row) => {
      return row.key == key;
    });
    return types[0];
  }

  setPaymentLocalStorage(payment: Payment) {
    const paymentStr = JSON.stringify(payment);
    localStorage.setItem("payment", paymentStr);
  }

  getPaymentLocalStorage(): Payment {
    const payment = localStorage.getItem("payment");
    return JSON.parse(payment);
  }
}
