import { Payment } from './../models/interfaces/payment';
import { Injectable } from '@angular/core';
import { DocumentChangeAction } from '@angular/fire/firestore';
import { parseISO, subHours } from 'date-fns';
import { Observable } from 'rxjs';
import { firebaseConsts } from '../constants';
import { StatusCall } from '../models/call';
import { ApiService } from './api/api.service';
import { DataService } from './data.service';
import { FirebaseDatabaseService } from './firebase-database.service';
import PaymentType from '../models/enuns/paymentType';
import { BalanceService } from './balance.service';
import { BalanceType } from '../models/enuns/balanceType';

@Injectable({
  providedIn: 'root'
})
export class CallService {
  constructor(
    public db: FirebaseDatabaseService,
    public api: ApiService,
    private balanceService: BalanceService
  ) { }

  getPendingCalls() {

  }

  updateCall(call) {
    return this.db.updateDoc(firebaseConsts.collections.calls, call);
  }

  public watchPendindCalls(
    clientKey,
    subsidiary
  ): Observable<DocumentChangeAction<any>[]> {
    const dateIni = subHours(new Date(), 72);
    let ref = this.db.afs.collection(firebaseConsts.collections.calls, (ref) =>
      ref.where("subsidiary", "==", subsidiary)
      .where("clientKey", "==", clientKey)
      .where("complete", "==", false)
      .orderBy("createdAt")
      .startAt(dateIni).limit(10)
    );
    return ref.stateChanges();
  }

  public watchCalls(
    status,
    subsidiary,
    collection
  ): Observable<DocumentChangeAction<any>[]> {
    let ref = this.db.afs.collection(collection, (ref) =>
      ref.where("subsidiary", "==", subsidiary).where("status", "==", status)
        .orderBy("createdAt")
    );
    return ref.snapshotChanges();
  }

  async getCallByTimeCourse() {
    const dataIni = parseISO('2022-06-01T00:00:00');
    const dataFin = parseISO('2022-06-10T23:59:00');
    let ref = this.db.afs.collection(firebaseConsts.collections.calls).ref;
    const callsRef = ref.where('status', '==', 'complete')
    // .where('payment.type', '==', PaymentType.Prepago)
    .orderBy("createdAt")
    .startAt(dataIni)
    .endAt(dataFin)
    const calls = await callsRef.get();
    return calls ;
  }

  async updateCalls() {
    const caslls: any = await this.getCallByTimeCourse();
    let total = 0;
    let count = 0;
    console.log('Calls: '+caslls.length);
    caslls.forEach(async element => {
      const call = element.data();
      // call.id = element.id;
      // if (!call.prepaid) {
      // let totalSubsidiaryFee = call.destinations.map((row: any) => row.tariff.subsidiaryFeeValue).reduce((prev, next) => prev + next);
      // console.log(totalSubsidiaryFee);
      
      // if (isNaN(totalSubsidiaryFee)) totalSubsidiaryFee = 0;

      // if (totalSubsidiaryFee == undefined) {// || totalSubsidiaryFee == 0
        count++;
        // total += call.payment.value;
        // const info = {
        //   id: call.id,
        //   device: call.device,
        //   data: new Date(call.createdAt.seconds * 1000),
        //   destinations: call.destinations
        // }
        // console.log(info);
      // }
      //   const tran = await this.balanceService.doTransactCall(call.origin.clientId, call.payment.value, BalanceType.Subtraction, call.id);
      //   console.log(tran.success);
      // }
    });
    console.log('Total', total);
    console.log('Quantidade', count);
    
    
  }

}
