import Dictionary from "../interfaces/dictionary";

/* eslint-disable no-unused-vars */
enum BalanceType {
    Addition = 'addition',
    Subtraction = 'subtraction'
  }
  
  enum BalanceStatus {
    Waiting = 'waiting',
    Approved = 'approved',
    Canceled = 'canceled'
  }

  enum TransactStatus {
    Done = 'done',
    Undone = 'undone'
  }
  
  enum PaymentBalanceType {
    Transfer = 0,
    CashDeposit = 1,
    Pix = 2,
    CreditCard = 3,
    DebitCard = 4,
    BankSlip = 5,
    Reversal = 99
  }

  const PaymentBalanceTypeList: Dictionary[] = [
    { key: 0, value: 'Tranferência' },
    { key: 1, value: 'Depósito em dinheiro' },
    { key: 2, value: 'Pix' },
    { key: 3, value: 'Cartão crédito' },
    { key: 4, value: 'Cartão débito' },
    { key: 5, value: 'Boleto bancário' },
    { key: 99, value: 'Estorno (Devolução)' }
  ];
  
  export { BalanceType, PaymentBalanceType, BalanceStatus, PaymentBalanceTypeList, TransactStatus };
  