import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

registerLocaleData(localePt, 'pt');

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { NgxMaskModule } from 'ngx-mask';
import { NgxLoadingModule } from 'ngx-loading';

// Import owners
import { ApiService } from './services/api/api.service';
import { googleParams } from './constants';
import { ReportsComponent } from './views/reports/reports.component';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './views/login/login.component';
import { ChooseDriverComponent } from './views/components/choose-driver/choose-driver.component';

// Import firebase modules
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { NgSelect2Module } from 'ng-select2';
import { QRCodeModule } from 'angularx-qrcode';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { AngularDeviceInformationService } from 'angular-device-information';
import { ToggleButtonComponent } from './views/components/toggle-button/toggle-button.component';

const config: SocketIoConfig = {
	url: environment.socketUrl,
	options: {
		transports: ['websocket'],
	}
}

  @NgModule({
    declarations: [
      AppComponent,
      ...APP_CONTAINERS,
      LoginComponent,
      ReportsComponent,
      ChooseDriverComponent,
      ToggleButtonComponent
    ],
    imports: [
      BrowserModule,
      HttpClientModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      NgxLoadingModule.forRoot({}),
      NgxMaskModule.forRoot(),
      FormsModule,
      AgmCoreModule.forRoot({
        apiKey: googleParams.directionsApikey
      }),
      ClipboardModule,
      AgmDirectionModule, 
      AppAsideModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule,
      AngularFireAuthModule,
      AngularFireStorageModule,
      AngularFireMessagingModule,
      SocketIoModule.forRoot(config),
      NgxSkeletonLoaderModule,
      AppBreadcrumbModule.forRoot(),
      AppFooterModule,
      AppHeaderModule,
      AppSidebarModule,
      PerfectScrollbarModule,
      BsDropdownModule.forRoot(),
      TabsModule.forRoot(),
      ChartsModule,
      AlertModule,
      NgSelect2Module,
      QRCodeModule
    ],
    schemas: [NO_ERRORS_SCHEMA],
    providers: [
      ApiService,
      AngularDeviceInformationService,
      {
        provide: LOCALE_ID,
        useValue: 'pt'
      },
    ],
    bootstrap: [AppComponent],
    exports: [ToggleButtonComponent]
  })
  export class AppModule { }
