import { MoneyService } from "./money.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { FirebaseDatabaseService } from "./firebase-database.service";
import { firebaseConsts, googleParams } from "../constants";
import {
  AngularFirestore,
  DocumentChangeAction,
  Query,
} from "@angular/fire/firestore";
import { DataService } from "./data.service";
import { Call, StatusCall } from "../models/call";
import { ApiService } from "./api/api.service";
import { BalanceType } from "../models/enuns/balanceType";
import { CategoryDriverList } from "../models/enuns/categoryDriver";
import { v4 as uuid } from "uuid";
import { Destination } from "../models/destination";
import PaymentType from "../models/enuns/paymentType";
import { subHours } from "date-fns";
import { GenericOption } from "../models/enuns/gerericOption";

export enum CalculationMethods {
  kilometerTable = 3,
  kilometerTableReturn = 4,
  kilometerTableOnRain = 5,
}

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  private selectedPaymentMethod = new BehaviorSubject<string>('');
  API_GEOCODE_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${googleParams.geocodeApikey}`;
  API_DIRECTIONS_URL = `https://maps.googleapis.com/maps/api/directions/json?key=${googleParams.directionsApikey}`; //origin=Disneyland&destination=Universal+Studios+Hollywood&key=YOUR_API_KEY
  constructor(
    private api: ApiService,
    public db: FirebaseDatabaseService,
    private data: DataService,
    private afs: AngularFirestore,
    private moneyService: MoneyService
  ) { }

  getSessionTokenUuid() {
    return uuid();
  }

  sendCall(call: any) {
    let customDestinations = call.destinations.map((x: any) => {
      if (x.address === "undefined") {
        x.address = `${x.addressObj.street}, ${x.addressObj.number}, ${x.addressObj.neighborhood}, ${x.addressObj.city} - ${x.addressObj.state}`;
      }
      return x;
    });
    call.destinations = customDestinations;
    return this.db.addDoc(firebaseConsts.collections.calls, call);
  }

  async getIp() {
    const res = await this.api.getCurrentIp();
    return res ? res.IPv4 : null;
  }

  sendCallApi(call) {
    let customDestinations = call.destinations.map((x: any) => {
      if (x.address === "undefined") {
        x.address = `${x.addressObj.street}, ${x.addressObj.number}, ${x.addressObj.neighborhood}, ${x.addressObj.city} - ${x.addressObj.state}`;
      }
      return x;
    });
    call.destinations = customDestinations;
    const data = {
      ...call,
      device: call.device,
      categoryDriver: call.categoryDriver,
      hasBack: call.hasBack,
      origin: {
        ...call.origin.address,
        location: {
          latitude: call.origin.address.location.latitude,
          longitude: call.origin.address.location.longitude,
        },
      },
      paymentType: call.payment,
      destinations: call.destinations,
    };
    return this.api.post(data, "calls");
  }

  updateCall(call) {
    return this.db.updateDoc(firebaseConsts.collections.calls, call);
  }

  public async getCalls(userKey) {
    let ref = this.afs.collection(firebaseConsts.collections.calls).ref;
    const dateIni = subHours(new Date(), 1);
    let query: Query;
    query = ref.where("clientKey", "==", userKey)
      .where("complete", "==", false)
      .orderBy("createdAt")
      .startAt(dateIni);
    return this.db.getCollection(query);
  }

  public watchCurrentDeliveries(
    clientId
  ): Observable<DocumentChangeAction<any>[]> {
    return this.db.watchCollectionByField(
      clientId,
      "currentCall.clientId",
      firebaseConsts.collections.online
    );
  }

  get pendingCount() {
    const res = this.data.waintinglist.filter((row) => {
      return (
        row.status === StatusCall.waiting || row.status === StatusCall.imported
      );
    });
    return res ? res.length : 0;
  }

  get activeCount() {
    if (this.data.waintinglist && this.data.waintinglist.length > 0) {
      const res = this.data.waintinglist.filter((row) => {
        return (
          row.status === StatusCall.ongoing ||
          row.status === StatusCall.accepted
        );
      });
      return res.length;
    } else return 0;
  }

  get sumRetainValue() {

    if (!this.data.waintinglist || this.data.waintinglist?.length == 0) return 0;

    const prePaidList = this.data.waintinglist.filter((row) => {
      return row.payment.type == PaymentType.Prepago;
    });
    const total = prePaidList.length ? prePaidList
      .map((row) => row.payment.value)
      .reduce((prev, next) => prev + next) : 0;

    return total;
  }

  getTariffsBySubsidiary(calculationMethod, driverCategory) {
    let ref = this.afs.collection(firebaseConsts.collections.tariffs).ref;
    let query: Query;
    query = ref
      .where("subsidiary", "==", this.data.client.subsidiary)
      .where("categoryDriver", "==", driverCategory)
      .where("company", "==", null)
      .where("calculationMethod", "==", calculationMethod);
    return this.db.getCollection(query);
  }

  getTariffsByCompany(calculationMethod, driverCategory) {
    let ref = this.afs.collection(firebaseConsts.collections.tariffs).ref;
    let query: Query;
    query = ref
      .where("company", "==", this.data.currentUserId())
      .where("categoryDriver", "==", driverCategory)
      .where("disabledAt", "==", null)
      .where("calculationMethod", "==", calculationMethod);
    return this.db.getCollection(query);
  }

  getDriversNear(body: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.post(body, "drivers/near").then((resp: any) => {
          resolve(resp);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  // filterBinary(arr, min, max) {
  //   var len = arr.length,
  //     up = -1,
  //     down = len,
  //     rrange = [],
  //     mid = Math.floor(len / 2);
  //   while (up++ < mid && down-- > mid) {
  //     if (arr[up] >= max || arr[down] <= min) {
  //       break;
  //     }
  //     if (arr[up] >= min) {
  //       rrange.push(arr[up]);
  //     }
  //     if (arr[down] <= max) {
  //       rrange.push(arr[down]);
  //     }
  //   }
  //   return rrange;
  // }

  unselectTariff() {
    for (
      let index = 0;
      index < this.data.currentTariffs[0].fixedValues.length;
      index++
    ) {
      this.data.currentTariffs[0].fixedValues[index].selected = false;
    }
  }

  public calcCost(routeKm: number, categoryDriver: number, hasReturn: boolean) {
    return new Promise(async (resolve, reject) => {
      let distance = Number(Number(routeKm).toFixed(2));
      try {
        let tariff: any;
        tariff = this.data.currentTariffs[0];
        if (!tariff) {
          resolve(0);
          this.data.warning =
            "Sem tarifas cadastradas. Valores serão exibidos zerados.";
          console.warn("Sem tarifas cadastradas");
        } else {
          await this.unselectTariff();
          this.data.warning = null;
          if (3 == CalculationMethods.kilometerTable) {
            const taxListByValue = await tariff.fixedValues.sort(function (
              a,
              b
            ) {
              if (a.distance < b.distance) return -1;
              if (a.distance > b.distance) return 1;
              return 0;
            });

            let likelyTaxes = await taxListByValue.filter((row: any) => {
              return distance <= row.distance;
            });

            let tariffUnit = likelyTaxes[0];

            if (!tariffUnit && distance <= taxListByValue[0].distance) {
              tariffUnit = taxListByValue[0];
            }

            if (distance > taxListByValue[taxListByValue.length - 1].value)
              tariffUnit = null;

            if (tariffUnit) {
              const index = this.data.currentTariffs[0].fixedValues.findIndex(
                (row) => {
                  return row.id === tariffUnit.id;
                }
              );
              if (index > -1)
                this.data.currentTariffs[0].fixedValues[index].selected = true;

              const valueTariff = Number(tariffUnit.value).toFixed(2);
              const valueReturnTariff = Number(
                this.data.currentTariffs[0].fixedValues[index].valueBack
                  ? this.data.currentTariffs[0].fixedValues[index].valueBack
                  : 0
              ).toFixed(2);
              const response = {
                valueTariff: valueTariff,
                valueReturnTariff: hasReturn ? valueReturnTariff : 0,
                params: this.data.currentTariffs[0].fixedValues[index],
                calculationMethod: CalculationMethods.kilometerTable,
                tariffName: this.data.currentTariffs[0].description,
                categoryDriver: categoryDriver,
              };
              resolve(response);
            } else {
              const highestFare = taxListByValue[taxListByValue.length - 1];
              // console.log(highestFare);
              const valuePerKm = highestFare.value / highestFare.distance;
              const valueTariff = Number(valuePerKm * distance).toFixed(2);

              const valuePerKmReturn = highestFare.valueBack / highestFare.distance;
              const valueReturnTariff = Number(valuePerKmReturn * distance).toFixed(2);

              const response = {
                valueTariff: valueTariff,
                valueReturnTariff: hasReturn ? valueReturnTariff : 0,
                params: null,
                calculationMethod: CalculationMethods.kilometerTable,
                tariffName: this.data.currentTariffs[0].description,
                categoryDriver: categoryDriver,
              };
              resolve(response);
              // https://suporte.machine.global/hc/pt-br/articles/360046369273-Tarifas-para-Entregas-Tabela-de-KM
            }
          } else {
            this.data.warning =
              "Sem tarifas cadastradas. Valores serão exibidos zerados.";
            console.warn("Sem tarifas");
            resolve(0);
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  async decreaseBalanceApi(callId: string, value: number) {
    const valueCents = this.moneyService.convertToCents(value);
    const body = {
      type: BalanceType.Subtraction,
      value: valueCents,
      callId: callId,
    };
    const resp = await this.api.post(body, "balance/transact");
    return resp;
  }

  async getDriverList(radius: number) {
    let driversList: any = [];
    const lat = this.data.client.address.location.latitude;
    const lng = this.data.client.address.location.longitude;
    const params = {
      category: this.data.defaultCategoryType,
      subsidiary: this.data.subsidiary.id ? this.data.subsidiary.id : this.data.selectedCall.subsidiary,
      radius: radius,
      center: {
        lat: lat,
        lng: lng,
      },
    };
    const driversResponse: any = await this.getDriversNear(params);
    if (driversResponse.success) {
      driversList = driversResponse.response;
      driversList.sort(function (a: any, b: any) {
        return a.distance - b.distance && b.lastCallMinutes - a.lastCallMinutes;
      });
    }
    return driversList;
  }

  async endCall(call: Call) {
    const pends = call.destinations.filter(row => {
      return row.status == 'pending'
    });

    if (pends.length == 1) {
      const data = {
        callId: call.id ?? call.key,
        location: {
          lat: call.origin.address.location.latitude,
          lng: call.origin.address.location.longitude,
        },
        action: 'complete',
        driver: {
          category: null,
          photoURL: this.data.currentUser.photoURL,
          phoneNumber: this.data.currentUser.phoneNumber,
          appVersion: null,
          fullName: this.data.client.name
        }
      };
      return this.api.post(data, "calls/setstatus");
    }
  }

  async sendCallIfood(call: Call) {
    call.drivers = [];
    let driversList = await this.getDriverList(0.2);

    if (driversList.length == 0) {
      driversList = await this.getDriverList(1.2);
    }

    const catDriver = CategoryDriverList.find((row) => {
      return row.key == this.data.defaultCategoryType;
    });

    call.categoryDriverName = catDriver.value;

    if (driversList.length) {
      for (let index = 0; index < driversList.length; index++) {
        const driver = driversList[index];
        if (call.drivers.length < 5) {
          call.drivers.push(driver.id);
        }
      }
    }

    call.availableDrivers = this.data.driversAvailables.filter(
      (row) => row.qtd > 0
    );

    const jsonCall = {
      drivers: call.drivers,
      stage: 1,
      key: this.data.selectedCall.key,
      status: StatusCall.waiting,
    };

    return await this.updateCall(jsonCall);
  }

  getValueCall(destination: Destination, value: number) {
    let newDest = destination;
    newDest.cost = value;
    newDest.durationInfo = '0 min';
    if (newDest.tariff.subsidiaryFeePerc && newDest.tariff.subsidiaryFeePerc > 0) {
      newDest.tariff.subsidiaryFeeValue = (newDest.tariff.subsidiaryFeePerc * value) * 0.01
    }
    newDest.tariff.value = value;

    return newDest;

  }

  private paymentOptions: GenericOption[] = [
    {
      id: '1',
      label: 'Dinheiro',
      iconUrl: 'assets/img/money_icon.png',
      value: '1'
    },
    {
      id: '3',
      label: 'Crédito',
      iconUrl: 'assets/img/money_icon.png',
      value: '3'
    },
    {
      id: '2',
      label: 'Débito',
      iconUrl: 'assets/img/money_icon.png',
      value: '2'
    }
  ];

  getPaymentOptions(): GenericOption[] {
    return this.paymentOptions;
  }

  setSelectedPaymentMethod(value: string): void {
    this.selectedPaymentMethod.next(value);
  }

  getSelectedPaymentMethod() {
    return this.selectedPaymentMethod.asObservable();
  }

}
