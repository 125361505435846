import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
  @Input() activeColor: string = '#4caf50'; // Cor quando ativado
  @Input() inactiveColor: string = '#ccc';  // Cor quando desativado
  @Input() isToggled: boolean = false; // Controle inicial do estado
  @Output() toggled = new EventEmitter<boolean>(); // Emissor de eventos

  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.isToggled = !this.isToggled;
    this.toggled.emit(this.isToggled);
  }

}
